import {
  createApp
} from 'vue';

import {
  Api,
  locale,
  urlAssets,
  SlugApplicationFilter,
  SlugCommercialFilter
} from './Api';

const app = createApp({
  data() {
    return {
      product: [],
      loading: true,
      error: null,
      activeTab: null,
      activeGrade: null,
      activeForm: false,
      urlAssets: urlAssets,
      form: {
        name: "",
        surname: "",
        company: "",
        email: "",
        phone_number: "",
        product_slug: "",
        product_grade_slug: "",
        branch: "",
        document_type: "",
        locale: "",
      },
      errors: {
        name: null,
        surname: null,
        company: null,
        email: null,
        phone_number: null,
      },
      ErrorMutation: false,
      UrlDownloadPdf: null,

      applicationfilters: [],
      activeApplicationFilter: [],
      activeApplicationFilterName: [],
      loadingApplicationFilters: true,
      errorApplicationFilters: null,

      commercialfilters: [],
      activeCommercialFilter: [],
      activeCommercialFilterName: [],
      loadingCommercialFilters: true,
      errorCommercialFilters: null,

      branchfilters: [],
      activeBranchFilter: [],
      activeBranchFilterName: [],
      loadingBranchFilters: true,
      errorBranchFilters: null,

      productionAreafilters: [],
      activeProductionAreaFilter: [],
      activeProductionAreaFilterName: [],
      loadingproductionAreaFilters: true,
      errorproductionAreaFilters: null,

      grades: [],
    };
  },

  watch: {
    activeGrade(newGrade) {
      if (newGrade) {
        // Trova il grade corrispondente al nuovo ID
        const grade = this.grades.find((g) => g.id === newGrade);
        if (grade && grade.grades.length > 0) {
          // Se il grade esiste e ha elementi, imposta la prima tab
          this.activeTab = grade.grades[0].commercial_sites[0].slug;
        }
      } else {
        // Se newGrade è null o undefined, imposta la prima tab
        const firstGrade = this.grades[0];
        if (firstGrade && firstGrade.grades.length > 0) {
          this.activeTab = firstGrade.grades[0].commercial_sites[0].slug;
        }
      }
     // console.log("Active tab set to:", this.activeTab);
    }
    
  },

  computed: {
    isFormValid() {
      return (
        !this.errors.name &&
        !this.errors.surname &&
        !this.errors.company &&
        !this.errors.email &&
        !this.errors.phone_number &&
        this.form.name &&
        this.form.surname &&
        this.form.company &&
        this.form.email &&
        this.form.phone_number
      );
    }
  },

  async mounted() {
    try {

      this.checkFilterFromUrlBranch();
      await this.fetchProducts();
      await this.fetchCommercialSiteFilters();
      await this.fetchApplicationFilters();
      this.verifyAvaibleFilter();

    } catch (error) {
      console.error("Errore nel processo di caricamento:", error);
    }
  },

  methods: {
    
    // Se si aggiunge un nuovo filtro bisogna indicare in base al tipo se ci sono filtri collegati che si devono aggiornare
    applyFilter(filterType, filterSlug, filterTitle) {
      const activeFilterKey = `active${filterType}Filter`;
      const activeFilterNameKey = `active${filterType}FilterName`;
  
      this[activeFilterKey] = this[activeFilterKey] || [];
      this[activeFilterNameKey] = this[activeFilterNameKey] || [];
      const filterIndex = this[activeFilterKey].indexOf(filterSlug);
  
      if (filterIndex > -1) {
          this[activeFilterKey].splice(filterIndex, 1);
          this[activeFilterNameKey].splice(filterIndex, 1);
      } else {
          this[activeFilterKey].push(filterSlug);   
          this[activeFilterNameKey].push(filterTitle);
      }

      if (filterType === 'Commercial') {
        this.fetchApplicationFilters();
      }

      if(filterType === 'Application') {
        this.fetchCommercialSiteFilters();
      }
    
      this.activeGrade = null;
      this.updateUrl();
      this.fetchProducts();
    },

    // async fetchBranchFilters() {
    //   if (!locale) {
    //     this.errorBranchFilters = 'Locale non è definito. Riprova più tardi.';
    //     this.loadingBranchFilters = false;
    //     return;
    //   }

    //   this.loadingBranchFilters = true;
    //   this.errorBranchFilters = null;

    //   const currentUrl = window.location.pathname;
    //   const ProductSlug = currentUrl.split('/').pop();

    //   const slug = `productSlug: "${ProductSlug}"`;

    //   const applicationsFilter = this.activeApplicationFilter ? `applications: "${this.activeApplicationFilter}"` : '';
    //   const productionAreasFilter = this.activeProductionAreaFilter ? `productionAreas: "${this.activeProductionAreaFilter}"` : '';
    //   const commercialFilter = this.activeCommercialFilter ? `commercialSites: "${this.activeCommercialFilter}"` : '';
    
    //   const filters = [applicationsFilter, productionAreasFilter, commercialFilter].filter(Boolean).join(', ');


    //   const query = `{
    //     branchFiltersForProductFamilyQuery(locale: "${locale}", ${filters}  ${slug}) {
    //       slug
    //       title
    //     }
    //   }`;

    //   console.log(query);

    //   try {
    //     const response = await fetch(Api, {
    //       method: 'POST',
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Methods": "POST",
    //       },
    //       body: JSON.stringify({ query }),
    //     });

    //     const result = await response.json();
    //     this.BranchFilter = result.data.branchFiltersForProductFamilyQuery;
    //   } catch (error) {
    //     this.errorBranchFilters = 'Errore nel recupero dei dati branch filter';
    //   } finally {
    //     this.loadingBranchFilters = false;
    //   }
    // },

    // async fetchProductionAreasFilters() {
    //   if (!locale) {
    //     this.errorproductionAreaFilters = 'Locale non è definito. Riprova più tardi.';
    //     this.loadingproductionAreaFilters = false;
    //     return;
    //   }

    //   this.loadingproductionAreaFilters = true;
    //   this.errorproductionAreaFilters = null;

    //   const currentUrl = window.location.pathname;
    //   const ProductSlug = currentUrl.split('/').pop();

    //   const slug = `productSlug: "${ProductSlug}"`;
      
    //   const applicationsFilter = this.activeApplicationFilter ? `applications: "${this.activeApplicationFilter}"` : '';
    //   const branchesFilter = this.activeBranchFilter ? `branches: "${this.activeBranchFilter}"` : '';
    //   const commercialFilter = this.activeCommercialFilter ? `commercialSites: "${this.activeCommercialFilter}"` : '';
    
    //   const filters = [applicationsFilter, branchesFilter, commercialFilter].filter(Boolean).join(', ');
    //   const query = `{
    //     productionAreaFiltersForProductFamilyQuery(locale: "${locale}", ${filters},  ${slug}) {
    //       slug
    //       title
    //     }
    //   }`;

    //   //console.log('Query area di produzione ' + query);

    //   try {
    //     const response = await fetch(Api, {
    //       method: 'POST',
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Methods": "POST",
    //       },
    //       body: JSON.stringify({ query }),
    //     });

    //     const result = await response.json();

    //     this.productionAreafilters = result.data.productionAreaFiltersForProductFamilyQuery;
    //   } catch (error) {
    //     this.errorproductionAreaFilters = 'Errore nel recupero dei dati production area filters';
    //   } finally {
    //     this.loadingproductionAreaFilters = false;
    //   }
    // },

    async fetchCommercialSiteFilters() {
      if (!locale) {
        this.errorCommercialFilters = 'Locale non è definito. Riprova più tardi.';
        this.loadingCommercialFilters = false;
        return;
      }

      this.loadingCommercialFilters = true;
      this.errorCommercialFilters = null;

      const currentUrl = window.location.pathname;
      const ProductSlug = currentUrl.split('/').pop();
      const applicationsFilter = Array.isArray(this.activeApplicationFilter) && this.activeApplicationFilter.length > 0
      ? `applications: ["${this.activeApplicationFilter.join('","')}"]`
      : '';

      const productSlug = `productSlug: "${ProductSlug}"`;

      const filters = [
        applicationsFilter,
        productSlug
      ].filter(filter => filter !== '' && filter !== null && filter !== undefined);

      const filtersString = filters.length > 0 ? `${filters.join(', ')}` : '';

      const query = `{
        commercialSiteFiltersForProductFamilyQuery(locale: "${locale}", ${filtersString}) {
          slug
          title
        }
      }`;

      //console.log('Siti commerciali ' + query);

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({ query }),
        });

        const result = await response.json();
        this.commercialfilters = result.data.commercialSiteFiltersForProductFamilyQuery;

      } catch (error) {
        this.errorCommercialFilters = 'Errore nel recupero dei dati application filter';
      } finally {
        this.loadingCommercialFilters = false;
      }
    },

    async fetchApplicationFilters() {
      if (!locale) {
        this.errorApplicationFilters = 'Locale non è definito. Riprova più tardi.';
        this.loadingApplicationFilters = false;
        return;
      }

      this.loadingApplicationFilters = true;
      this.errorApplicationFilters = null;

      const currentUrl = window.location.pathname;
      const ProductSlug = currentUrl.split('/').pop();

      const commercialFilter = Array.isArray(this.activeCommercialFilter) && this.activeCommercialFilter.length > 0
        ? `commercialSites: [${this.activeCommercialFilter.map(site => `"${site.trim()}"`).join(', ')}]`
        : '';

      const productSlug = `productSlug: "${ProductSlug}"`;

      const filters = [
        commercialFilter,
        productSlug
      ].filter(filter => filter !== '' && filter !== null && filter !== undefined);

      const filtersString = filters.length > 0 ? `${filters.join(', ')}` : '';

      const query = `{
        applicationFiltersForProductFamilyQuery(locale: "${locale}", ${filtersString}) {
          slug
          title
        }
      }`;
      

      //console.log('Query applicazioni ' + query);

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({ query }),
        });

        const result = await response.json();
        this.applicationfilters = result.data.applicationFiltersForProductFamilyQuery;
      } catch (error) {
        this.errorApplicationFilters = 'Errore nel recupero dei dati application filter';
      } finally {
        this.loadingApplicationFilters = false;
      }
    },

    async fetchProducts() {
      if (!locale) {
        this.error = 'Locale non è definito. Riprova più tardi.';
        this.loading = false;
        return;
      }

      this.loading = true;
      this.error = null;

      const currentUrl = window.location.pathname;
      const ProductSlug = currentUrl.split('/').pop();
      const slug = `slug: "${ProductSlug}"`;
      
      const applicationsFilter = Array.isArray(this.activeApplicationFilter) && this.activeApplicationFilter.length > 0
      ? `applications: ["${this.activeApplicationFilter.join('","')}"]`
      : '';
      const commercialFilter = Array.isArray(this.activeCommercialFilter) && this.activeCommercialFilter.length > 0
      ? `commercialSites: [${this.activeCommercialFilter.map(site => `"${site.trim()}"`).join(', ')}]`
      : '';
      
      const filters = [
        applicationsFilter,
        commercialFilter
      ].filter(filter => filter !== '' && filter !== null && filter !== undefined);
      

      const filtersString = filters.length > 0 ? `(${filters.join(', ')})` : '';
      
      const query = `{
        product(locale: "${locale}", ${slug}) {
          id
          slug
          title
          description
          image {
            title
            url
          }
          applications {
            title
            slug
            icon {
              url
            }
          }
          product_grades${filtersString} {
            grade_slug
            id
            slug
            title
            grades {
              slug
              id
              title
              carbon_footprint_certified
              first_title(locale: "${locale}")
              second_title(locale: "${locale}")
              third_title(locale: "${locale}")
              first_description
              second_description
              third_description
              applications {
                title
                slug
                icon {
                  url
                }
              }
              branches {
                id
                slug
                title
              }
              commercial_sites {
                title
                slug
              }
              other_documents {
                id
                title
                url
              }
              safety_data_sheet {
                id
                title
                url
              }
              technical_data_sheet {
                id
                title
                url
              }
            }
          }
        }
      }`;

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({
            query: query
          }),
        });

        const result = await response.json();
        this.product = result.data.product[0];
        this.grades = this.product.product_grades;
        this.manipolateData();

      } 
      catch (error) {
        this.error = 'Errore nel recupero dei dati';
      } finally {
        this.loading = false;
      }
    },

    manipolateData() {
      this.product.product_grades.forEach(grade => {
        grade.grades.forEach(singleGrade => {
          singleGrade.commercial_sites.forEach(site => {
            site.applications = singleGrade.applications || '';
            site.first_description = singleGrade.first_description || '';
            site.second_description = singleGrade.second_description || '';
            site.third_description = singleGrade.third_description || '';
            site.first_title = singleGrade.first_title || '';
            site.second_title = singleGrade.second_title || '';
            site.third_title = singleGrade.third_title || '';
            site.carbon_footprint_certified = singleGrade.carbon_footprint_certified || false;
            site.technical_data_sheet = singleGrade.technical_data_sheet || [];
            site.safety_data_sheet = singleGrade.safety_data_sheet || [];
            site.other_documents = singleGrade.other_documents || [];
          });
        });
      });
    },

    validateForm() {
      // Validazione del campo "name"
      if (!this.form.name) {
        this.errors.name = 'Il nome è obbligatorio.';
      } else if (this.form.name.length < 2) {
        this.errors.name = 'Il nome deve contenere almeno 2 caratteri.';
      } else {
        this.errors.name = null;
      }

      // Validazione del campo "surname"
      if (!this.form.surname) {
        this.errors.surname = 'Il cognome è obbligatorio.';
      } else if (this.form.surname.length < 2) {
        this.errors.surname = 'Il cognome deve contenere almeno 2 caratteri.';
      } else {
        this.errors.surname = null;
      }

      // Validazione del campo "company"
      if (!this.form.company) {
        this.errors.company = 'L\'azienda è obbligatoria.';
      } else {
        this.errors.company = null;
      }

      // Validazione del campo "email"
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.form.email) {
        this.errors.email = 'L\'email è obbligatoria.';
      } else if (!emailPattern.test(this.form.email)) {
        this.errors.email = 'Inserisci un indirizzo email valido.';
      } else {
        this.errors.email = null;
      }

      // Validazione del campo "phone_number"
      const phonePattern = /^[0-9]{10,15}$/;
      if (!this.form.phone_number) {
        this.errors.phone_number = 'Il numero di telefono è obbligatorio.';
      } else if (!phonePattern.test(this.form.phone_number)) {
        this.errors.phone_number = 'Inserisci un numero di telefono valido.';
      } else {
        this.errors.phone_number = null;
      }
    },

    async submitForm() {

      this.validateForm();

      if (this.isFormValid) {
        const mutation = `
          mutation {
            createCustomer(
              name: "${this.form.name}",
              surname: "${this.form.surname}",
              company: "${this.form.company}",
              email: "${this.form.email}",
              phone_number: "${this.form.phone_number}",
              product_slug: "${this.form.product_slug}",
              product_grade_slug: "${this.form.product_grade_slug}",
              branch: "${this.form.branch}",
              document_type: "${this.form.document_type}",
              locale: "${this.form.locale}"
            ) {
              name
            }
          }
        `;

        try {
          const response = await fetch(Api, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: mutation
            }),
          });

          const result = await response.json();

          if (result.data) {
            this.activeForm = false;
            this.resetForm();
            window.open(this.UrlDownloadPdf, '_blank');
          } else {
            this.ErrorMutation = true;
          }
        } catch (error) {
          this.ErrorMutation = true;
        }
      }
    },

    tab(id) {
      this.activeTab = id;
    },

    toggleAccordion(id) {
      this.activeGrade = this.activeGrade === id ? null : id;
    },

    RequestDownload(file, product_slug, product_grade_slug, branch, url) {
      this.activeForm = !this.activeForm;
      this.form.document_type = file;
      this.form.locale = locale;
      this.form.product_slug = product_slug;
      this.form.product_grade_slug = product_grade_slug;
      this.form.branch = branch;
      this.UrlDownloadPdf = url;
    },

    async submitForm() {
      const mutation = `
        mutation {
          createCustomer(
              name: "${this.form.name}",
              surname: "${this.form.surname}",
              company: "${this.form.company}",
              email: "${this.form.email}",
              phone_number: "${this.form.phone_number}",
              product_slug: "${this.form.product_slug}",
              product_grade_slug: "${this.form.product_grade_slug}",
              branch: "${this.form.branch}",
              document_type: "${this.form.document_type}",
              locale: "${this.form.locale}"
          ) {
            name
          }
        }
      `;

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: mutation
          }),
        });

        const result = await response.json();

        if (result.data) {
          this.activeForm = false;
          this.resetForm();
          window.open(this.UrlDownloadPdf, '_blank');

        } else {
          this.ErrorMutation = true;
        }
      } catch (error) {
        this.ErrorMutation = true;
      }
    },

    resetForm() {
      this.form = {
        name: '',
        surname: '',
        company: '',
        email: '',
        phone_number: '',
        product_slug: '',
        product_grade_slug: '',
        branch: '',
        document_type: '',
        locale: locale,
      };

      this.errors = {
        name: null,
        surname: null,
        company: null,
        email: null,
        phone_number: null,
      };
    },

    checkFilterFromUrlBranch() {
      const searchParams = new URLSearchParams(window.location.search);
    
      const commercialSlugs = searchParams.get(SlugCommercialFilter)?.split(',') || [];
      this.activeCommercialFilter = commercialSlugs;
      this.activeCommercialFilterName = commercialSlugs.map(slug => slug.replace(/-/g, ' '));
    
      const applicationSlugs = searchParams.get(SlugApplicationFilter)?.split(',') || [];
      this.activeApplicationFilter = applicationSlugs;
      this.activeApplicationFilterName = applicationSlugs.map(slug => slug.replace(/-/g, ' '));
    },
    
    verifyAvaibleFilter() {

      const validApplicationSlugs = this.applicationfilters.map(filter => filter.slug);
      const validCommercialSlugs = this.commercialfilters.map(filter => filter.slug);
    
      this.activeApplicationFilter = this.activeApplicationFilter.filter(filter =>
        validApplicationSlugs.includes(filter)
      );

      this.activeApplicationFilterName = this.activeApplicationFilterName.filter(filter =>
        validApplicationSlugs.some(slug => slug.replace(/-/g, ' ') === filter)
      );
    
      this.activeCommercialFilter = this.activeCommercialFilter.filter(filter =>
        validCommercialSlugs.includes(filter)
      );

      this.activeCommercialFilterName = this.activeCommercialFilterName.filter(filter =>
        validCommercialSlugs.some(slug => slug.replace(/-/g, ' ') === filter)
      );
    },

    updateUrl() {
      let newUrl = window.location.pathname;
    
      if (this.activeCommercialFilter.length) {
        newUrl += `?${SlugCommercialFilter}=${this.activeCommercialFilter.join(',')}`;
      }
    
      if (this.activeApplicationFilter.length) {
        // Se il nuovo URL già contiene un '?', aggiungi '&'
        newUrl += newUrl.includes('?') ? `&${SlugApplicationFilter}=${this.activeApplicationFilter.join(',')}` : `?${SlugApplicationFilter}=${this.activeApplicationFilter.join(',')}`;
      }
    
      if (history.pushState) {
        if (newUrl !== window.location.pathname + window.location.search) {
          history.pushState(null, '', newUrl);
        }
      } else {
        if (newUrl !== window.location.pathname + window.location.search) {
          window.location.href = newUrl;
        }
      }
    },

  }
});

const archive = document.getElementById('single-prodotto');

if (archive) {
  app.mount('#single-prodotto');
}