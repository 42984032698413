import {
  createApp
} from 'vue';

import {
  Api,
  urlAssets,
  locale
} from './Api';


const app = createApp({
  data() {
    return {
      Application: [],
      loading: true,
      error: null,
      urlAssets: urlAssets,
      initialCount: 6,
      additionalCount: 6, // Per ogni click mostra altri 6 elementi
    };
  },

  computed: {
    displayedApplications() {
      return this.Application.slice(0, this.initialCount);
    },
    remainingApplications() {
      return this.Application.length - this.initialCount;
    }
  },

  mounted() {
    this.fetchApplication();
  },

  methods: {

    showMore() {
      this.initialCount += this.additionalCount;
    },

    async fetchApplication() {
      
      // Controlla se "locale" ha un valore prima di procedere
      if (!locale) {
        this.error = 'Locale non è definito. Riprova più tardi.';
        this.loading = false;
        return;
      }

      this.loading = true;
      this.error = null;

      const currentUrl = window.location.pathname;
      const ApplicationSlug = currentUrl.split('/').pop();

      const query = `{
            application(locale: "${locale}") {
              id
              slug
              title
              icon {
                id
                title
                url
              }
              preview_image {
                url
              }
            }
        }`;

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({
            query: query
          }),
        });

        const result = await response.json();

        if(ApplicationSlug != '') {
          this.Application = result.data.application.filter(application => application.slug !== ApplicationSlug);
        }
        else {
          this.Application = result.data.application;
        }

      } catch (error) {
        this.error = 'Non è stato possibile recuperare i dati.';
      } finally {
        this.loading = false;
      }
    },
  }
});

const archive = document.getElementById('settore-applicazione');

if (archive) {
  app.mount('#settore-applicazione');
}