import {
  createApp
} from 'vue';

import {
  Api,
  urlAssets,
  locale
} from './Api';


const app = createApp({
  data() {
    return {
      application: [],
      branchFilters: [],
      products: [],
      loading: true,
      error: null,
      urlAssets: urlAssets,
    };
  },

  mounted() {
    this.fetchApplication();
  },

  methods: {
    async fetchApplication() {
      if (!locale) {
        this.error = 'Locale non è definito. Riprova più tardi.';
        this.loading = false;
        return;
      }

      this.loading = true;
      this.error = null;

      // Extract the application slug from the URL
      const currentUrl = window.location.pathname;
      const applicationSlug = currentUrl.split('/').pop();

      const slug = `slug: "${applicationSlug}"`;
      const query = `{
            application(locale: "${locale}", ${slug}) {
              title
              description
              icon {
                url
              }
              image {
                url
              }
            }
            commercialSiteFilters(locale: "${locale}", applications: ["${applicationSlug}"]) {
              title
              slug
            }
            products(locale: "${locale}", applications: ["${applicationSlug}"]) {
              title
              slug
              description
              short_description
              applications {
              title
              slug
                icon {
                  url
                }
              }
            }
          }`;

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({
            query: query
          }),
        });

        const result = await response.json();
        this.application = result.data.application[0];
        this.branchFilters = result.data.commercialSiteFilters;
        this.products = result.data.products;
      } catch (error) {
        this.error = 'Errore nel recupero dei dati';
      } finally {
        this.loading = false;
      }
    },
  }
});

const archive = document.getElementById('single-settore');

if (archive) {
  app.mount('#single-settore');
}