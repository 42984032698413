import { createApp } from 'vue';
import { Api, urlAssets, locale, SlugApplicationFilter, SlugCommercialFilter } from './Api';

const app = createApp({
  data() {
    return {
      products: [],
      loading: true,
      error: null,
      urlAssets: urlAssets,

      applicationfilters: [],
      activeApplicationFilter: [],
      activeApplicationFilterName: [],
      loadingApplicationFilters: true,
      errorApplicationFilters: null,

      commercialfilters: [],
      activeCommercialFilter: [],
      activeCommercialFilterName: [],
      loadingCommercialFilters: true,
      errorCommercialFilters: null,

      branchfilters: [],
      activeBranchFilter: [],
      activeBranchFilterName: [],
      loadingBranchFilters: true,
      errorBranchFilters: null,

      productionAreafilters: [],
      activeProductionAreaFilter: [],
      activeProductionAreaFilterName: [],
      loadingproductionAreaFilters: true,
      errorproductionAreaFilters: null,
    };
  },

  computed: {
    productUrl() {
      let queryParams = '';

      if (this.activeCommercialFilter.length) {
        queryParams += `${SlugCommercialFilter}=${this.activeCommercialFilter.join(',')}`;
      }
      
      if (this.activeApplicationFilter.length) {
        if (queryParams) {
          queryParams += '&';
        }
        queryParams += `${SlugApplicationFilter}=${this.activeApplicationFilter.join(',')}`;
      }
      
      return queryParams ? `?${queryParams}` : '';
    }
  },  

  created() {
    window.addEventListener('popstate', this.handlePopState);
  },

  beforeUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  },

  async mounted() {
    this.checkFilterFromUrlBranch();
    await this.fetchProducts();
    await this.fetchCommercialSiteFilters();
    await this.fetchApplicationFilters();
  },

  methods: {

    applyFilter(filterType, filterSlug, filterTitle) {
      const activeFilterKey = `active${filterType}Filter`;
      const activeFilterNameKey = `active${filterType}FilterName`;
  
      this[activeFilterKey] = this[activeFilterKey] || [];
      this[activeFilterNameKey] = this[activeFilterNameKey] || [];
      const filterIndex = this[activeFilterKey].indexOf(filterSlug);
  
      if (filterIndex > -1) {
          this[activeFilterKey].splice(filterIndex, 1);
          this[activeFilterNameKey].splice(filterIndex, 1);
      } else {
          this[activeFilterKey].push(filterSlug);   
          this[activeFilterNameKey].push(filterTitle);
      }
  
      if (filterType === 'Commercial') {
          
          this.fetchApplicationFilters();
      }
  
      if (filterType === 'Application') {
          this.fetchCommercialSiteFilters();
      }
  
      this.updateUrl();
      this.fetchProducts();
  },
  
    async fetchProducts() {
      if (!locale) {
        this.error = 'Locale non è definito. Riprova più tardi.';
        this.loading = false;
        return;
      }
    
      this.loading = true;
      this.error = null;
    
      const applicationsFilter = this.activeApplicationFilter.length ? `applications: ["${this.activeApplicationFilter.join('","')}"]` : '';
      const branchesFilter = this.activeBranchFilter.length ? `branches: ["${this.activeBranchFilter.join('","')}"]` : '';
      const productionAreasFilter = this.activeProductionAreaFilter.length ? `productionAreas: ["${this.activeProductionAreaFilter.join('","')}"]` : '';
      const commercialFilter = this.activeCommercialFilter.length ? `commercialSites: ["${this.activeCommercialFilter.join('","')}"]` : '';
  
      const filters = [applicationsFilter, branchesFilter, productionAreasFilter, commercialFilter]
        .filter(Boolean)
        .join(', ');
    
      const query = `{
        products (locale: "${locale}", ${filters}) {
          slug
          title
          description
          short_description
          id
          applications {
            slug
            title
            icon {
              url
              title
            }
          }
        }
      }`;

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({ query }),
        });
    
        const result = await response.json();
        this.products = result.data.products;
      } catch (error) {
        this.error = 'Errore nel recupero dei dati';
      } finally {
        this.loading = false;
      }
    },

    async fetchApplicationFilters() {
      if (!locale) {
        this.errorApplicationFilters = 'Locale non è definito. Riprova più tardi.';
        this.loadingApplicationFilters = false;
        return;
      }

      this.loadingApplicationFilters = true;
      this.errorApplicationFilters = null;

      const branchesFilter = this.activeBranchFilter.length ? `branches: ["${this.activeBranchFilter.join('","')}"]` : '';
      const productionAreasFilter = this.activeProductionAreaFilter.length ? `productionAreas: ["${this.activeProductionAreaFilter.join('","')}"]` : '';
      const commercialFilter = this.activeCommercialFilter.length ? `commercialSites: ["${this.activeCommercialFilter.join('","')}"]` : '';
      const filters = [productionAreasFilter, branchesFilter, commercialFilter].filter(Boolean).join(', ');

      const query = `{
        applicationFilters(locale: "${locale}", ${filters}) {
          slug
          title
        }
      }`;

      //console.log('Query applicazioni ' + query);

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({ query }),
        });

        const result = await response.json();
        //console.log(result);
        this.applicationfilters = result.data.applicationFilters;
      } catch (error) {
        this.errorApplicationFilters = 'Errore nel recupero dei dati application filter';
      } finally {
        this.loadingApplicationFilters = false;
      }
    },

    // async fetchBranchFilters() {
    //   if (!locale) {
    //     this.errorBranchFilters = 'Locale non è definito. Riprova più tardi.';
    //     this.loadingBranchFilters = false;
    //     return;
    //   }

    //   this.loadingBranchFilters = true;
    //   this.errorBranchFilters = null;

    //   const query = `{
    //     branch(locale: "${locale}") {
    //       id
    //       slug
    //       title
    //     }
    //   }`;

    //   try {
    //     const response = await fetch(Api, {
    //       method: 'POST',
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Methods": "POST",
    //       },
    //       body: JSON.stringify({ query }),
    //     });

    //     const result = await response.json();
    //     this.BranchFilter = result.data.branch;
    //   } catch (error) {
    //     this.errorBranchFilters = 'Errore nel recupero dei dati branch filter';
    //   } finally {
    //     this.loadingBranchFilters = false;
    //   }
    // },

    // async fetchProductionAreasFilters() {
    //   if (!locale) {
    //     this.errorproductionAreaFilters = 'Locale non è definito. Riprova più tardi.';
    //     this.loadingproductionAreaFilters = false;
    //     return;
    //   }

    //   this.loadingproductionAreaFilters = true;
    //   this.errorproductionAreaFilters = null;

    // const applicationsFilter = this.activeApplicationFilter.length ? `applications: ["${this.activeApplicationFilter.join('","')}"]` : '';
    // const branchesFilter = this.activeBranchFilter.length ? `branches: ["${this.activeBranchFilter.join('","')}"]` : '';
    // const commercialFilter = this.activeCommercialFilter.length ? `commercialSites: ["${this.activeCommercialFilter.join('","')}"]` : '';
      
    //   const filters = [applicationsFilter, branchesFilter, commercialFilter].filter(Boolean).join(', ');
    //   const query = `{
    //     productionAreaFilters(locale: "${locale}", ${filters}) {
    //       slug
    //       title
    //     }
    //   }`;

    //   console.log('Query area di produzione ' + query);

    //   try {
    //     const response = await fetch(Api, {
    //       method: 'POST',
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Methods": "POST",
    //       },
    //       body: JSON.stringify({ query }),
    //     });

    //     const result = await response.json();

    //     this.productionAreafilters = result.data.productionAreaFilters;
    //   } catch (error) {
    //     this.errorproductionAreaFilters = 'Errore nel recupero dei dati production area filters';
    //   } finally {
    //     this.loadingproductionAreaFilters = false;
    //   }
    // },

    async fetchCommercialSiteFilters() {
      if (!locale) {
        this.errorCommercialFilters = 'Locale non è definito. Riprova più tardi.';
        this.loadingCommercialFilters = false;
        return;
      }

      this.loadingCommercialFilters = true;
      this.errorCommercialFilters = null;

      const applicationsFilter = this.activeApplicationFilter.length ? `applications: ["${this.activeApplicationFilter.join('","')}"]` : '';
      const branchesFilter = this.activeBranchFilter.length ? `branches: ["${this.activeBranchFilter.join('","')}"]` : '';
      const productionAreasFilter = this.activeProductionAreaFilter.length ? `productionAreas: ["${this.activeProductionAreaFilter.join('","')}"]` : '';

      const filters = [productionAreasFilter, branchesFilter, applicationsFilter].filter(Boolean).join(', ');
      const query = `{
        commercialSiteFilters(locale: "${locale}", ${filters}) {
          slug
          title
        }
      }`;

      //console.log('Siti commerciali ' + query);

      try {
        const response = await fetch(Api, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "POST",
          },
          body: JSON.stringify({ query }),
        });

        const result = await response.json();
        this.commercialfilters = result.data.commercialSiteFilters;

      } catch (error) {
        this.errorCommercialFilters = 'Errore nel recupero dei dati application filter';
      } finally {
        this.loadingCommercialFilters = false;
      }
    },

    checkFilterFromUrlBranch() {
      const searchParams = new URLSearchParams(window.location.search);
    
      const commercialSlugs = searchParams.get(SlugCommercialFilter)?.split(',') || [];
      this.activeCommercialFilter = commercialSlugs;
      this.activeCommercialFilterName = commercialSlugs.map(slug => slug.replace(/-/g, ' '));
    
      const applicationSlugs = searchParams.get(SlugApplicationFilter)?.split(',') || [];
      this.activeApplicationFilter = applicationSlugs;
      this.activeApplicationFilterName = applicationSlugs.map(slug => slug.replace(/-/g, ' '));
    
    },
    
    updateUrl() {
      let newUrl = window.location.pathname;
    
      if (this.activeCommercialFilter.length) {
        newUrl += `?${SlugCommercialFilter}=${this.activeCommercialFilter.join(',')}`;
      }
    
      if (this.activeApplicationFilter.length) {
        // Se il nuovo URL già contiene un '?', aggiungi '&'
        newUrl += newUrl.includes('?') ? `&${SlugApplicationFilter}=${this.activeApplicationFilter.join(',')}` : `?${SlugApplicationFilter}=${this.activeApplicationFilter.join(',')}`;
      }
    
      if (history.pushState) {
        if (newUrl !== window.location.pathname + window.location.search) {
          history.pushState(null, '', newUrl);
        }
      } else {
        if (newUrl !== window.location.pathname + window.location.search) {
          window.location.href = newUrl;
        }
      }
    },

    handlePopState() {
      this.checkFilterFromUrlBranch();
    },
  }
});

const archive = document.getElementById('catalogo-prodotti');

if (archive) {
  app.mount('#catalogo-prodotti');
}